import React from "react";
import { Link } from "gatsby";
import { Layout } from "../components/Layout";

export default () => {
  return (
    <Layout pageTitle="sayfa bulunamadı" path="404">
      <h1>sayfa bulunamadı</h1>
      <p>
        aradığınız sayfa bulunamadı. bir soru olduğunu düşünüyorsanız{" "}
        <a href="mailto:tolunyuksel@gmail.com">tolunyuksel@gmail.com</a>{" "}
        adresine mail atarak bildirebilir veya <Link to="/">ana sayfaya</Link>{" "}
        dönebilirsiniz.
      </p>
    </Layout>
  );
};
